import React, { useMemo } from "react";
import { captureException } from "@sentry/react";
import { buffer, point, booleanContains } from "@turf/turf";

import { useAirportsContext } from "../../../../context/airports";
import { selectSigmets } from "../../sigmetSlice";
import { useAppSelector } from "../../../../hooks";
import { AirportDocument, Sigmet } from "@weatheredstrip/shared";
import { Position } from "geojson";

const NEARBY_THRESHOLD = 100; // Threshold in nautical miles

const SIGMET_GEOMETRY_MAPPING = {
  AREA: "Polygon",
  AREAS: "MultiPolygon",
  LINE: "LineString",
  LINES: "MultiLineString",
  POINT: "Point",
  POINTS: "MultiPoint",
} as const;

type ExpectedGeoJsonType =
  | GeoJSON.Point
  | GeoJSON.MultiPoint
  | GeoJSON.LineString
  | GeoJSON.MultiLineString
  | GeoJSON.Polygon
  | GeoJSON.MultiPolygon;

function closePolygonIfRequired(coordinates: Position[]): Position[] {
  if (
    coordinates[0][0] !== coordinates[coordinates.length - 1][0] ||
    coordinates[0][1] !== coordinates[coordinates.length - 1][1]
  ) {
    coordinates.push(coordinates[0]);
  }
  return coordinates;
}

function interpolationBetweenTwoPoints(
  start: { lat: number; lon: number },
  end: { lat: number; lon: number },
  numPoints: number
): { lat: number; lon: number } {
  const latDiff = end.lat - start.lat;
  const lonDiff = end.lon - start.lon;
  const latStep = latDiff / 2;
  const lonStep = lonDiff / 2;
  return { lat: start.lat + latStep, lon: start.lon + lonStep };
}

function makeGeoJsonFrom(sigmet: Sigmet): ExpectedGeoJsonType {
  const usedSigmet = { ...sigmet };

  if (usedSigmet.geom === "AREA") {
    if (usedSigmet.coords.length === 3) {
      const newCoords = [...usedSigmet.coords];
      const lastCoord = usedSigmet.coords[usedSigmet.coords.length - 1] as {
        lat: number;
        lon: number;
      };
      const secondLastCoord = usedSigmet.coords[
        usedSigmet.coords.length - 2
      ] as {
        lat: number;
        lon: number;
      };

      newCoords.splice(
        usedSigmet.coords.length - 1,
        0,
        interpolationBetweenTwoPoints(secondLastCoord, lastCoord, 2)
      );

      usedSigmet.coords = newCoords;
    }
    return {
      type: SIGMET_GEOMETRY_MAPPING[usedSigmet.geom],
      coordinates: [
        closePolygonIfRequired(
          usedSigmet.coords.map(
            (coord: { lon: number; lat: number }) =>
              [coord.lon, coord.lat] as Position
          )
        ),
      ],
    };
  } else if (usedSigmet.geom === "LINE") {
    return {
      type: SIGMET_GEOMETRY_MAPPING[usedSigmet.geom],
      coordinates: usedSigmet.coords.map(
        (coord: { lon: number; lat: number }) =>
          [coord.lon, coord.lat] as Position
      ),
    };
  } else if (usedSigmet.geom === "POINT") {
    const coord = usedSigmet.coords[0] as { lon: number; lat: number };
    return {
      type: SIGMET_GEOMETRY_MAPPING[usedSigmet.geom],
      coordinates: [coord[0].lon, coord[0].lat] as Position,
    };
  } else if (usedSigmet.geom === "POINTS") {
    const coords = usedSigmet.coords as { lon: number; lat: number }[];
    return {
      type: SIGMET_GEOMETRY_MAPPING[usedSigmet.geom],
      coordinates: coords.map((coord) => [coord.lon, coord.lat] as Position),
    };
  } else if (usedSigmet.geom === "LINES") {
    const coords = usedSigmet.coords as { lon: number; lat: number }[];
    return {
      type: SIGMET_GEOMETRY_MAPPING[usedSigmet.geom],
      coordinates: [coords.map((coord) => [coord.lon, coord.lat] as Position)],
    };
  } else if (usedSigmet.geom === "AREAS") {
    const coordinates = usedSigmet.coords.map(
      (coords: { lon: number; lat: number }[]) => {
        return closePolygonIfRequired(
          coords.map((coord) => [coord.lon, coord.lat] as Position)
        );
      }
    );

    return {
      type: SIGMET_GEOMETRY_MAPPING[usedSigmet.geom],
      coordinates: [coordinates],
    };
  } else {
    throw new Error(`Unsupported geometry type: ${usedSigmet.geom}`);
  }
}

export function filterNearbySigmets<
  A extends AirportDocument,
  S extends Sigmet
>(sigmets: S[], selectedAirport: A | null): S[] {
  if (!selectedAirport) return [];

  const filteredSigmets = sigmets.filter((sigmet) => {
    if (sigmet.geom) {
      const shape = makeGeoJsonFrom(sigmet);

      const shapeWithBuffer = buffer(shape, NEARBY_THRESHOLD, {
        units: "nauticalmiles",
      });

      if (!shapeWithBuffer) {
        captureException(new Error(`Failed to create buffer for polygon`));
        return false;
      }

      const airportPoint = point([
        Number(selectedAirport.longitude_deg),
        Number(selectedAirport.latitude_deg),
      ]);

      return booleanContains(shapeWithBuffer, airportPoint);
    } else {
      captureException(new Error(`Geometry type is undefined`));
      return false;
    }
  });

  return filteredSigmets;
}

const useStationSigmets = () => {
  const airports = useAirportsContext();
  const sigmets = useAppSelector(selectSigmets);
  const selectedAirport = airports.selected;

  return useMemo(() => {
    return filterNearbySigmets(sigmets, selectedAirport);
  }, [sigmets, selectedAirport]);
};

function Sigmets() {
  const sigmets = useStationSigmets();

  return sigmets.length > 0 ? (
    <div>
      <div className="subtitle">SIGMET</div>
      {sigmets.map((sigmet) => (
        <div key={sigmet.seriesId} className="notif-text">
          {sigmet.rawSigmet}
        </div>
      ))}
    </div>
  ) : null;
}

export default Sigmets;
